import Vue from "vue";
import Vuex from "vuex";
import state from "./store/state";
import mutations from "./store/mutations";
import getters from "./store/getters";
import actions from "./store/actions";

Vue.use(Vuex);

let store = new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
});

export default store;
