<template>
  <nav>
    <v-app-bar color="primary">
      <v-img contain height="100%" max-width="20%" :src="getSrc()"></v-img>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="ml-auto" dark v-bind="attrs" v-on="on">
            mdi-menu
          </v-icon>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title @click="navigateTo('/home')">
              {{ $t("common.homePage") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title @click="navigateTo('/products-list')">
              {{ $t("common.product") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="isNewOfferCreated">
            <v-list-item-title @click="uploadProjects()">
              {{ $t("common.upload") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <v-checkbox
                v-model="showPrice"
                hide-details
                class="mr-2 mt-0 text--black"
                :label="$t('common.showPrice')"
              ></v-checkbox>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title @click="logout">
              {{ $t("common.logout") }}
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </nav>
</template>

<script>
import router from "../router";
import store from "../store";
import axios from "axios";
import { getAllRequestData } from "../cache";

export default {
  name: "Navbar",
  computed: {
    showPrice: {
      get() {
        return store.state.showPrice;
      },
      set(value) {
        store.state.showPrice = value;
      },
    },
    isNewOfferCreated: () => {
      return store.state.isNewOfferCreated;
    },
  },
  methods: {
    navigateTo(url) {
      router.push(url).catch(() => {});
    },
    logout() {
      localStorage.clear();
      router.push("/").catch(() => {});
    },
    uploadProjects() {
      axios
        .post(`/offer`, store.state.projectList)
        .then(() => {
          getAllRequestData();
          store.state.isNewOfferCreated = false;
          router.push("/home").catch(() => {});
        })
        .catch(() => {
          store.commit("updateAlert", {
            status: "error",
            message: "offer.error",
          });
        });
    },
    getSrc() {
      return store.state.companyLogo;
    },
  },
};
</script>

<style scoped></style>
