<template>
  <v-app>
    <v-main v-bind:class="{ blur: isLoadingPage() }">
      <router-view />
    </v-main>

    <div class="spinner-div" v-if="isLoadingPage()">
      <v-progress-circular
        class="spinner"
        :size="50"
        color="black"
        indeterminate
      ></v-progress-circular>
    </div>

    <div class="mx-5 text-center zindex" v-if="status" @click="closeAlert()">
      <v-alert :type="status">{{ message }}</v-alert>
    </div>
  </v-app>
</template>

<script>
import store from "./store";
import router from "./router";

export default {
  name: "App",
  methods: {
    isLoadingPage() {
      return store.state.isLoading && router.currentRoute.name !== "LoginPage";
    },
    closeAlert() {
      store.state.alertStatus = "";
      store.state.alertMessage = "";
    },
  },
  computed: {
    status: () => {
      return store.state.alertStatus;
    },
    message: () => {
      return store.state.alertMessage;
    },
  },
};
</script>

<style>
.spinner-div {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 10;
}
.spinner {
  align-self: center;
}
.blur {
  filter: blur(3px);
  -webkit-filter: blur(3px);
}
.zindex {
  z-index: 400;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
