<template>
  <section>
    <Navbar />

    <v-container fluid>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-btn color="success" @click="goToOfferDetails()">
            {{ $t("common.offerDetails") }}
          </v-btn>
        </v-col>

        <v-col cols="6" class="text-center">
          <v-btn color="info" @click="goToEditProject()">
            {{ $t("common.editProject") }}
          </v-btn>
        </v-col>

        <v-col cols="6" class="text-center">
          <v-btn color="error" @click="removeProject()">
            {{ $t("common.deleteProject") }}
          </v-btn>
        </v-col>

        <v-col cols="12" class="text-center">
          <v-btn
            color="accent"
            v-if="selectedFirstCategory && !showNewProject"
            @click="goBack()"
          >
            {{ $t("common.back") }}
          </v-btn>

          <v-btn color="accent" v-if="showNewProject" @click="goPojectBack()">
            {{ $t("common.back") }}
          </v-btn>
        </v-col>

        <v-col cols="12" class="text-center">
          <v-btn
            v-if="selectedThirdCategory"
            @click="addMultipleProductsToOffer()"
          >
            {{ $t("product.addOffer") }}
          </v-btn>
        </v-col>

        <v-col class="text-center pt-0">
          <h1>{{ $t("product.listTitle") }}</h1>
        </v-col>
      </v-row>
    </v-container>

    <NewProjectPage
      v-if="showNewProject"
      :project="getSelectedProject()"
    ></NewProjectPage>

    <v-container fluid v-if="!selectedProduct && !showNewProject">
      <v-row
        v-if="
          !selectedFirstCategory &&
          !selectedSecondCategory &&
          !selectedThirdCategory
        "
      >
        <v-col
          class="text-center"
          cols="12"
          v-for="category in categories"
          :key="category.id"
        >
          <v-btn class="text-break" @click="selectedFirstCategory = category">
            {{ category.value }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row
        v-if="
          selectedFirstCategory &&
          !selectedSecondCategory &&
          !selectedThirdCategory
        "
      >
        <v-col
          class="text-center"
          cols="12"
          v-for="category in secondCategories"
          :key="category.id"
        >
          <v-btn class="text-break" @click="selectedSecondCategory = category">
            {{ category.value }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row
        v-if="
          selectedFirstCategory &&
          selectedSecondCategory &&
          !selectedThirdCategory
        "
      >
        <v-col
          class="text-center"
          cols="12"
          v-for="category in thirdCategories"
          :key="category.id"
        >
          <v-btn class="text-break" @click="selectedThirdCategory = category">
            {{ category.value }}
          </v-btn>
        </v-col>
      </v-row>

      <v-spacer></v-spacer>

      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="6"
          v-for="product in productList"
          :key="product.id"
        >
          <v-card class="height">
            <v-card-title>{{ product.title }}</v-card-title>

            <v-card-subtitle>{{ product.price | price }}</v-card-subtitle>

            <v-row class="align-baseline justify-center">
              <v-col cols="1" class="text-center px-0">
                <v-icon @click="removeQuantity(product)">
                  mdi-minus-circle-outline
                </v-icon>
              </v-col>

              <v-col cols="1" class="text-center px-0">
                <v-text-field
                  v-model="quantities[product.id]"
                  @change="changeQuantity(product, $event)"
                ></v-text-field>
              </v-col>

              <v-col cols="1" class="text-center px-0">
                <v-icon @click="addQuantity(product)"
                  >mdi-plus-circle-outline</v-icon
                >
              </v-col>
            </v-row>

            <v-card-actions>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-btn @click="selectProduct(product)" color="orange" text>
                    {{ $t("product.details") }}
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-btn
                    @click="addProductToOffer(product)"
                    color="orange"
                    text
                  >
                    {{ $t("product.addOffer") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <ProductDetailsPage
      v-if="selectedProduct && !showNewProject"
      :view-for-offer="true"
      :product="selectedProduct"
      @back-to-list="backToList()"
    />

    <v-overlay :value="overlay">
      <h3>{{ $t("product.added") }}</h3>
    </v-overlay>
  </section>
</template>

<script>
import store from "../store";
import Navbar from "./Navbar";
import router from "../router";
import ProductDetailsPage from "./ProductDetailsPage";
import NewProjectPage from "./NewProjectPage";
import axios from "axios";
import { getAllRequestData } from "../cache";

export default {
  name: "OfferProductListPage",
  data: () => ({
    quantities: [],
    overlay: false,
    showNewProject: false,
    selectedFirstCategory: null,
    selectedSecondCategory: null,
    selectedThirdCategory: null,
    selectedProduct: null,
  }),
  created() {
    this.categories = store.state.categories;
  },
  components: {
    Navbar,
    NewProjectPage,
    ProductDetailsPage,
  },
  methods: {
    getSelectedProject() {
      return store.state.selectedProject;
    },
    goBack() {
      if (this.selectedProduct) {
        this.selectedProduct = null;
      } else if (this.selectedThirdCategory) {
        this.selectedThirdCategory = null;
      } else if (this.selectedSecondCategory) {
        this.selectedSecondCategory = null;
      } else {
        this.selectedFirstCategory = null;
      }
    },
    backToList() {
      this.selectedProduct = null;
      this.selectedThirdCategory = null;
      this.selectedSecondCategory = null;
      this.selectedFirstCategory = null;
    },
    goPojectBack() {
      this.showNewProject = false;
    },
    goToOfferDetails() {
      router.push("/offer-details").catch(() => {});
    },
    goToEditProject() {
      this.showNewProject = true;
    },
    selectProduct(product) {
      this.selectedProduct = product;
    },
    removeProject() {
      axios
        .delete("/project/" + store.state.selectedProject.id)
        .then(() => {
          getAllRequestData();
          router.push("/home").catch(() => {});
        })
        .catch(() => {
          store.commit("updateAlert", {
            status: "error",
            message: "project.error",
          });
        });
    },
    addMultipleProductsToOffer() {
      for (let i = 0; i < this.quantities.length; i++) {
        if (
          typeof this.quantities[i] === "undefined" ||
          this.quantities[i] === 0
        ) {
          continue;
        }
        this.addProductToOffer(
          this.selectedThirdCategory?.productList.find((x) => x.id === i)
        );
      }
    },
    addProductToOffer(product) {
      product.quantity = this.quantities[product.id];

      store.state.basket = store.state.basket.filter((prod) => {
        return prod.id !== product.id;
      });

      if (this.quantity !== 0) {
        store.state.basket.push(product);
      }

      this.overlay = true;
      setTimeout(() => {
        this.overlay = false;
      }, 2000);
    },
    initializeQuantities() {
      for (let i = 0; i < this.selectedThirdCategory?.productList.length; i++) {
        this.quantities[this.selectedThirdCategory?.productList[i].id] =
          typeof this.selectedThirdCategory?.productList[i].quantity ===
          "undefined"
            ? 0
            : this.selectedThirdCategory?.productList[i].quantity;
      }
    },
    addQuantity(product) {
      this.quantities[product.id]++;
      this.$forceUpdate();
    },
    removeQuantity(product) {
      this.quantities[product.id] =
        this.quantities[product.id] > 0 ? this.quantities[product.id] - 1 : 0;
      this.$forceUpdate();
    },
    changeQuantity(product, newValue) {
      this.quantities[product.id] = newValue;
      this.$forceUpdate();
    },
  },
  computed: {
    categories: {
      get() {
        return store.state.categories;
      },
      set(value) {
        return value;
      },
    },
    secondCategories: {
      get() {
        let second = this.categories?.filter((category) => {
          return category.id === this.selectedFirstCategory.id;
        });
        return second.length > 0 ? second[0].subCategories : [];
      },
      set(value) {
        return value;
      },
    },
    thirdCategories: {
      get() {
        let third = this.secondCategories?.filter((category) => {
          return category.id === this.selectedSecondCategory.id;
        });
        return third.length > 0 ? third[0].subCategories : [];
      },
      set(value) {
        return value;
      },
    },
    productList: {
      get() {
        if (this.selectedThirdCategory) {
          this.initializeQuantities();
          return this.selectedThirdCategory?.productList || [];
        } else if (this.selectedSecondCategory) {
          return this.selectedSecondCategory?.productList || [];
        } else if (this.selectedFirstCategory) {
          return this.selectedFirstCategory?.productList || [];
        } else {
          return [];
        }
      },
      set(value) {
        return value;
      },
    },
  },
};
</script>

<style scoped>
button {
  width: 100%;
}
</style>
