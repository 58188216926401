var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('Navbar'),_c('ProjectButtons',{on:{"showNewProjectEvent":function($event){_vm.showNewProject = true;
      _vm.showProjectList = false;
      _vm.showSentProjectList = false;},"showProjectListEvent":function($event){_vm.showProjectList = true;
      _vm.showNewProject = false;
      _vm.showSentProjectList = false;},"showSentProjectListEvent":function($event){_vm.showSentProjectList = true;
      _vm.showProjectList = false;
      _vm.showNewProject = false;}}}),(_vm.showNewProject)?_c('NewProjectPage',{on:{"showProjectListEvent":function($event){_vm.showProjectList = true;
      _vm.showNewProject = false;}}}):_vm._e(),(_vm.showProjectList)?_c('ProjectListPage'):_vm._e(),(_vm.showSentProjectList)?_c('SentProjectListPage'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }