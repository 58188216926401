var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('Navbar'),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"accent"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v(" "+_vm._s(_vm.$t("common.back"))+" ")])],1)],1)],1),_c('v-container',[_c('v-row',[_c('v-col',[_c('h1',[_vm._v(" "+_vm._s(((_vm.selectedProject.lastName) + " " + (_vm.selectedProject.firstName)))+" ")]),_c('h1',[_vm._v(" "+_vm._s(("" + (_vm.selectedProject.email)))+" ")])])],1)],1),_c('v-data-table',{staticClass:"elevation-0 mt-5 justify-center",attrs:{"mobile-breakpoint":0,"headers":_vm.headerArray,"items":_vm.basket,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([(!_vm.isSentProject)?{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete")])],1)]}}:null,{key:"item.index",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}}],null,true)}),_c('v-divider',{staticClass:"mb-1"}),_c('v-divider'),_c('v-container',[(!_vm.isSentProject && _vm.showPrice)?_c('v-row',[_c('v-col',[_c('span',[_c('v-text-field',{attrs:{"label":_vm.$t('offer.additionalPrice')},model:{value:(_vm.additionalPrice),callback:function ($$v) {_vm.additionalPrice=$$v},expression:"additionalPrice"}})],1)])],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"col-12 text-right"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("totalPrice")(((_vm.$t("offer.totalPrice")) + " " + (_vm.getTotalPrice().toFixed(2)))))+" ")])]),_c('v-col',{staticClass:"col-12 text-right"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("tax")(_vm._f("totalPrice")(((_vm.$t("offer.totalTax")) + " " + ((_vm.getTotalPrice() * _vm.tax).toFixed(2))))))+" ")])]),_c('v-col',{staticClass:"col-12 text-right"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("totalPrice")(((_vm.$t("offer.totalPriceWithTax")) + " " + (_vm.getTotalPriceWithTax()))))+" ")])])],1)],1),_c('v-container',[_c('v-row',[(!_vm.isSentProject)?_c('v-col',{staticClass:"col-12 text-right"},[_c('v-btn',{attrs:{"color":"success","disabled":_vm.basket.length === 0},on:{"click":function($event){return _vm.saveOffer()}}},[_vm._v(" "+_vm._s(_vm.$t("common.save"))+" ")])],1):_vm._e(),(
          _vm.selectedProject.offerProducts.length > 0 &&
          !_vm.selectedProject.isChanged &&
          _vm.basket.length === _vm.selectedProject.offerProducts.length
        )?_c('v-col',{staticClass:"col-12 text-right"},[_c('v-btn',{attrs:{"color":"info","disabled":_vm.basket.length === 0},on:{"click":function($event){return _vm.sendEmail()}}},[_vm._v(" "+_vm._s(_vm.$t("common.sendEmail"))+" ")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }