<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="text-center">{{ product.title }}</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <span>{{ product.description }}</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-right">
        <v-carousel :height="calculateHeight()">
          <v-carousel-item
            v-for="img in product.images"
            :key="img.id"
            :src="getSrc(img)"
          ></v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>

    <v-row class="align-baseline justify-center">
      <v-col cols="1" class="text-center px-0">
        <v-icon @click="quantity = quantity > 0 ? quantity - 1 : 0">
          mdi-minus-circle-outline
        </v-icon>
      </v-col>

      <v-col cols="1" class="text-center px-0">
        <v-text-field v-model="quantity"></v-text-field>
      </v-col>

      <v-col cols="1" class="text-center px-0">
        <v-icon @click="quantity++">mdi-plus-circle-outline</v-icon>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <span>
          {{
            `${$t("product.pricePerQuantity")} ${product.price.toFixed(2)}`
              | price
          }}
        </span>
      </v-col>
      <v-col cols="12" v-if="showPrice">
        <span>
          {{
            `${$t("product.taxPerQuantity")}
              ${product.price.toFixed(2) * tax}`
              | price
              | tax
          }}
        </span>
      </v-col>
      <v-col cols="12">
        <span>
          {{
            `${$t("product.pricePerQuantityWithTax")}
              ${product.price.toFixed(2) * (1 + tax)}` | price
          }}
        </span>
      </v-col>

      <v-col cols="12" v-if="showPrice">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12">
        <span>
          {{
            `${$t("product.priceSummary")} ${(product.price * quantity).toFixed(
              2
            )}` | totalPrice
          }}
        </span>
      </v-col>
      <v-col cols="12">
        <span>
          {{
            `${$t("product.taxSummary")} ${(
              product.price *
              quantity *
              tax
            ).toFixed(2)}`
              | totalPrice
              | tax
          }}
        </span>
      </v-col>
      <v-col cols="12">
        <span>
          {{
            `${$t("product.priceSummaryWithTax")} ${(
              product.price *
              quantity *
              (1 + tax)
            ).toFixed(2)}` | totalPrice
          }}
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-right">
        <v-btn @click="addProductToOffer()">
          {{ $t("product.addOffer") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "../store";

export default {
  name: "ProductDetailsPage",
  data: () => ({
    quantity: 0,
    tax: 0,
  }),
  props: ["viewForOffer", "product"],
  computed: {
    showPrice: function () {
      return store.state.showPrice;
    },
  },
  created() {
    this.tax = store.state.tax;
    let usedProduct = store.state.basket.filter((prod) => {
      return prod.id === this.product.id;
    });
    if (usedProduct.length > 0) {
      this.quantity = usedProduct[0].quantity;
    }
  },
  methods: {
    getSrc(img) {
      return store.state.images.get(img.id);
    },
    addProductToOffer() {
      let product = this.product;
      product.quantity = this.quantity;

      store.state.basket = store.state.basket.filter((prod) => {
        return prod.id !== product.id;
      });

      if (this.quantity !== 0) {
        store.state.basket.push(product);
      }
      this.$emit("back-to-list");
    },
    calculateHeight() {
      return window.innerWidth - window.innerWidth * 0.3;
    },
  },
};
</script>

<style scoped></style>
