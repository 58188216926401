<template>
  <section>
    <Navbar />

    <v-container fluid>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-btn color="accent" @click="goBack()">
            {{ $t("common.back") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col>
          <h1>
            {{ `${selectedProject.lastName} ${selectedProject.firstName}` }}
          </h1>
          <h1>
            {{ `${selectedProject.email}` }}
          </h1>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :mobile-breakpoint="0"
      :headers="headerArray"
      :items="basket"
      :items-per-page="-1"
      hide-default-footer
      class="elevation-0 mt-5 justify-center"
    >
      <template v-if="!isSentProject" v-slot:item.action="{ item }">
        <div class="text-center">
          <v-icon small @click="deleteItem(item)"> mdi-delete</v-icon>
        </div>
      </template>

      <template v-slot:item.index="{ index }">
        {{ index + 1 }}
      </template>
    </v-data-table>

    <v-divider class="mb-1"></v-divider>
    <v-divider></v-divider>

    <v-container>
      <v-row v-if="!isSentProject && showPrice">
        <v-col>
          <span>
            <v-text-field
              v-model="additionalPrice"
              :label="$t('offer.additionalPrice')"
            ></v-text-field>
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12 text-right">
          <span>
            {{
              `${$t("offer.totalPrice")} ${getTotalPrice().toFixed(2)}`
                | totalPrice
            }}
          </span>
        </v-col>
        <v-col class="col-12 text-right">
          <span>
            {{
              `${$t("offer.totalTax")} ${(getTotalPrice() * tax).toFixed(2)}`
                | totalPrice
                | tax
            }}
          </span>
        </v-col>
        <v-col class="col-12 text-right">
          <span>
            {{
              `${$t("offer.totalPriceWithTax")} ${getTotalPriceWithTax()}`
                | totalPrice
            }}
          </span>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col v-if="!isSentProject" class="col-12 text-right">
          <v-btn
            color="success"
            @click="saveOffer()"
            :disabled="basket.length === 0"
          >
            {{ $t("common.save") }}
          </v-btn>
        </v-col>
        <v-col
          class="col-12 text-right"
          v-if="
            selectedProject.offerProducts.length > 0 &&
            !selectedProject.isChanged &&
            basket.length === selectedProject.offerProducts.length
          "
        >
          <v-btn
            color="info"
            @click="sendEmail()"
            :disabled="basket.length === 0"
          >
            {{ $t("common.sendEmail") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import Navbar from "./Navbar";
import store from "../store";
import router from "../router";
import i18n from "../i18n";
import axios from "axios";
import { getAllRequestData } from "../cache";

export default {
  name: "OfferDetailsPage",
  data: () => ({
    selectedProject: null,
    basket: null,
    tax: 0,
    isSentProject: store.state.showSentProjectOffer,
    additionalPrice: 0,
  }),
  computed: {
    headerArray: () => {
      let header = [
        { text: i18n.t("offer.position"), value: "index" },
        { text: i18n.t("offer.title"), value: "title" },
        { text: i18n.t("offer.quantity"), value: "quantity" },
      ];
      if (store.state.showPrice)
        header.push({ text: i18n.t("offer.price"), value: "price" });
      header.push({ text: "", value: "action", sortable: false });
      return header;
    },
    showPrice: {
      get() {
        return store.state.showPrice;
      },
    },
  },
  created() {
    this.tax = store.state.tax;
    this.selectedProject = store.state.selectedProject;
    this.additionalPrice = store.state.selectedProject?.additionalPrice || 0;
    this.basket = store.state.basket;
  },
  components: {
    Navbar,
  },
  methods: {
    deleteItem(product) {
      this.basket = this.basket.filter((prod) => {
        return prod.id !== product.id;
      });
      store.state.basket = this.basket;
    },
    getTotalPrice() {
      let totalPrice = 0;
      this.basket.forEach((prod) => {
        totalPrice += prod.quantity * prod.price;
      });
      totalPrice = totalPrice * (1 + this.additionalPrice / 100);
      return totalPrice;
    },
    getTotalPriceWithTax() {
      let totalWithTax =
        this.getTotalPrice() +
        Number((this.getTotalPrice() * this.tax).toFixed(2));
      return Number(totalWithTax).toFixed(2);
    },
    goBack() {
      router.back();
    },
    saveOffer() {
      let project = store.state.projectList.filter((project) => {
        return project.id === this.selectedProject.id;
      })[0];
      project.isChanged = true;
      project.additionalPrice = this.additionalPrice;
      project.isNewOfferCreated = true;
      project.offerProducts = [...this.basket];
      store.state.isNewOfferCreated = true;
      router.push("/home").catch(() => {});
    },
    sendEmail() {
      axios
        .post(`/offer-email/${this.selectedProject.id}`)
        .then(() => {
          getAllRequestData();
          router.push("/home").catch(() => {});
        })
        .catch(() => {
          store.commit("updateAlert", {
            status: "error",
            message: "offer.error",
          });
        });
    },
  },
};
</script>

<style scoped>
button {
  width: 100%;
}
</style>
