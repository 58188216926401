<template>
  <section>
    <Navbar />
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-btn
            color="success"
            v-if="!createNewProductPage && !selectedFirstCategory"
            @click="createNewProductPage = true"
          >
            {{ $t("product.createNewProject") }}
          </v-btn>

          <v-btn
            color="accent"
            v-if="createNewProductPage"
            @click="createNewProductPage = false"
          >
            {{ $t("common.back") }}
          </v-btn>

          <v-btn color="accent" v-if="selectedFirstCategory" @click="goBack()">
            {{ $t("common.back") }}
          </v-btn>
        </v-col>
      </v-row>

      <NewProductPage
        v-if="createNewProductPage"
        :edit-mode="false"
        :product="null"
        :first="null"
        :second="null"
        :third="null"
      />
      <NewProductPage
        v-if="selectProduct"
        :edit-mode="true"
        :product="selectProduct"
        :first="selectedFirstCategory"
        :second="selectedSecondCategory"
        :third="selectedThirdCategory"
        @back-to-list="backToList()"
      />

      <v-row
        v-if="
          !createNewProductPage &&
          !selectedFirstCategory &&
          !selectedSecondCategory &&
          !selectedThirdCategory &&
          !selectProduct
        "
      >
        <v-col
          class="text-center"
          cols="12"
          v-for="category in categories"
          :key="category.id"
        >
          <v-btn @click="selectedFirstCategory = category">
            {{ category.value }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row
        v-if="
          !createNewProductPage &&
          selectedFirstCategory &&
          !selectedSecondCategory &&
          !selectedThirdCategory &&
          !selectProduct
        "
      >
        <v-col
          class="text-center"
          cols="12"
          v-for="category in secondCategories"
          :key="category.id"
        >
          <v-btn @click="selectedSecondCategory = category">
            {{ category.value }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row
        v-if="
          !createNewProductPage &&
          selectedFirstCategory &&
          selectedSecondCategory &&
          !selectedThirdCategory &&
          !selectProduct
        "
      >
        <v-col
          class="text-center"
          cols="12"
          v-for="category in thirdCategories"
          :key="category.id"
        >
          <v-btn @click="selectedThirdCategory = category">
            {{ category.value }}
          </v-btn>
        </v-col>
      </v-row>

      <v-spacer></v-spacer>

      <v-row v-if="!selectProduct">
        <v-col cols="6" v-for="product in productList" :key="product.id">
          <v-card @click="selectProduct = product">
            <v-card-title>{{ product.title }}</v-card-title>
            <v-card-text>{{ product.description }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import Navbar from "./Navbar";
import store from "../store";
import NewProductPage from "./NewProductPage";

export default {
  name: "ProductListPage",
  data: () => ({
    selectedFirstCategory: null,
    selectedSecondCategory: null,
    selectedThirdCategory: null,
    selectProduct: null,
    createNewProductPage: false,
  }),
  created() {
    this.categories = store.state.categories;
  },
  components: {
    Navbar,
    NewProductPage,
  },
  methods: {
    goBack() {
      if (this.selectProduct) {
        this.selectProduct = null;
      } else if (this.selectedThirdCategory) {
        this.selectedThirdCategory = null;
      } else if (this.selectedSecondCategory) {
        this.selectedSecondCategory = null;
      } else {
        this.selectedFirstCategory = null;
      }
    },
    backToList() {
      this.selectProduct = null;
      this.selectedThirdCategory = null;
      this.selectedSecondCategory = null;
      this.selectedFirstCategory = null;
    },
  },
  computed: {
    categories: {
      get() {
        return store.state.categories;
      },
      set(value) {
        return value;
      },
    },
    secondCategories: {
      get() {
        let second = this.categories?.filter((category) => {
          return category.id === this.selectedFirstCategory.id;
        });
        return second.length > 0 ? second[0].subCategories : [];
      },
      set(value) {
        return value;
      },
    },
    thirdCategories: {
      get() {
        let third = this.secondCategories?.filter((category) => {
          return category.id === this.selectedSecondCategory.id;
        });
        return third.length > 0 ? third[0].subCategories : [];
      },
      set(value) {
        return value;
      },
    },
    productList: {
      get() {
        if (this.selectedThirdCategory) {
          return this.selectedThirdCategory?.productList || [];
        } else if (this.selectedSecondCategory) {
          return this.selectedSecondCategory?.productList || [];
        } else if (this.selectedFirstCategory) {
          return this.selectedFirstCategory?.productList || [];
        } else {
          return [];
        }
      },
      set(value) {
        return value;
      },
    },
  },
};
</script>

<style scoped>
button {
  width: 100%;
}
</style>
