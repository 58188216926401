import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "./components/LoginPage";
import HomePage from "./components/HomePage";
import ProductListPage from "./components/ProductListPage";
import OfferProductListPage from "./components/OfferProductListPage";
import { getAllRequestData } from "./cache";
import OfferDetailsPage from "./components/OfferDetailsPage";
import ProductDetailsPage from "./components/ProductDetailsPage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/home",
    name: "HomePage",
    component: HomePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/products-list",
    name: "ProductListPage",
    component: ProductListPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/offer",
    name: "OfferProductListPage",
    component: OfferProductListPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/offer-details",
    name: "OfferDetailsPage",
    component: OfferDetailsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/product-details",
    name: "ProductDetailsPage",
    component: ProductDetailsPage,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (
      !localStorage.getItem("X-AUTH-USER") &&
      !localStorage.getItem("X-AUTH-TOKEN")
    ) {
      next({
        path: "/",
        params: { nextUrl: to.fullPath },
      });
    } else {
      if (!from.name) {
        getAllRequestData();
      }
      next();
    }
  } else {
    if (
      localStorage.getItem("X-AUTH-USER") &&
      localStorage.getItem("X-AUTH-TOKEN")
    ) {
      getAllRequestData();
      next({
        path: "/home",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  }
});

export default router;
