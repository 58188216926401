let state = {
  projectList: [],
  sentProjectList: [],
  showSentProjectOffer: false,
  categories: [],
  basket: [],
  images: new Map(),
  isNewOfferCreated: false,
  isLoading: false,
  selectedProject: null,
  alertStatus: "",
  alertMessage: "",
  alertTimer: null,
  showPrice: true,
  companyLogo: null,
  tax: 0,
};

export default state;
