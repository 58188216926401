<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="onSubmit">
    <v-container fluid>
      <v-row>
        <v-col>
          <h1>{{ $t("project.title") }}</h1>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            v-model="form.firstName"
            :label="$t('project.firstName')"
            required
            validate-on-blur
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            v-model="form.lastName"
            :label="$t('project.lastName')"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            v-model="form.streetHouseNumber"
            :label="$t('project.streetHouseNumber')"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            v-model="form.zipCode"
            :label="$t('project.zipCode')"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            v-model="form.city"
            :label="$t('project.city')"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            v-model="form.phone"
            :label="$t('project.phone')"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            v-model="form.email"
            :label="$t('project.email')"
            type="email"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-center">
          <v-btn color="success" type="submit">
            {{ $t("project.save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import store from "../store";
import axios from "axios";
import { getAllRequestData } from "../cache";

export default {
  name: "NewProjectPage",
  props: ["project"],
  data: () => ({
    valid: false,
    form: {
      firstName: "",
      lastName: "",
      streetHouseNumber: "",
      zipCode: "",
      city: "",
      phone: "",
      email: "",
    },
  }),
  created() {
    if (this.project) {
      this.form.id = this.project.id;
      this.form.firstName = this.project.firstName;
      this.form.lastName = this.project.lastName;
      this.form.streetHouseNumber = this.project.streetHouseNumber;
      this.form.zipCode = this.project.zipCode;
      this.form.city = this.project.city;
      this.form.phone = this.project.phone;
      this.form.email = this.project.email;
    }
  },
  methods: {
    onSubmit() {
      if (this.isValid()) {
        axios
          .post(this.project ? "/update-project" : "/project", this.form)
          .then(() => {
            this.$emit("showProjectListEvent", false);
            getAllRequestData();
            this.form.firstName = "";
            this.form.lastName = "";
            this.form.streetHouseNumber = "";
            this.form.zipCode = "";
            this.form.city = "";
            this.form.phone = "";
            this.form.email = "";
          })
          .catch(() => {
            store.commit("updateAlert", {
              status: "error",
              message: "project.error",
            });
          });
      }
    },
    isValid() {
      let checkValidity = this.$refs.form.$el.checkValidity();
      if (!checkValidity) {
        store.commit("updateAlert", {
          status: "error",
          message: "project.valid",
        });
      }
      return checkValidity;
    },
  },
};
</script>

<style scoped>
button {
  width: 100%;
}
</style>
