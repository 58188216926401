import axios from "axios";
import store from "./store";

function getProjectList() {
  return axios
    .get("/project-list")
    .then((response) => {
      store.state.projectList = response.data;
      localStorage.setItem("projectList", JSON.stringify(response.data));
    })
    .catch(() => {
      store.state.projectList = JSON.parse(localStorage.getItem("projectList"));
    });
}

function getSentProjectList() {
  return axios
    .get("/sent-project-list")
    .then((response) => {
      store.state.sentProjectList = response.data;
      localStorage.setItem("sentProjectList", JSON.stringify(response.data));
    })
    .catch(() => {
      store.state.sentProjectList = JSON.parse(
        localStorage.getItem("sentProjectList")
      );
    });
}

function getCategories() {
  return axios
    .get("categories")
    .then((response) => {
      store.state.categories = response.data;
      localStorage.setItem("categories", JSON.stringify(response.data));
    })
    .catch(() => {
      store.state.categories = JSON.parse(localStorage.getItem("categories"));
    });
}

function getImageList() {
  return axios.get("images-list").then((response) => {
    return response.data;
  });
}

function getImages(imageList) {
  let promiseArray = [];
  imageList.forEach((img) => {
    promiseArray.push(
      axios.get("image/" + img.id).then((response) => {
        store.state.images.set(img.id, response.data);
      })
    );
  });
  return promiseArray;
}

function getCompanyLogo() {
  return axios
    .get("/company-logo")
    .then((response) => {
      store.state.companyLogo = response.data;
      localStorage.setItem("companyLogo", JSON.stringify(response.data));
    })
    .catch(() => {
      store.state.companyLogo = JSON.parse(localStorage.getItem("companyLogo"));
    });
}

function getTax() {
  return axios
    .get("/tax")
    .then((response) => {
      store.state.tax = response.data;
      localStorage.setItem("tax", JSON.stringify(response.data));
    })
    .catch(() => {
      store.state.tax = JSON.parse(localStorage.getItem("tax"));
    });
}

export function getAllRequestData() {
  store.state.isLoading = true;
  Promise.all([
    getProjectList(),
    getCategories(),
    getImageList(),
    getCompanyLogo(),
    getTax(),
    getSentProjectList(),
  ])
    .then((response) => {
      if (response[2]) {
        Promise.all(getImages(response[2])).then(() => {
          store.state.isLoading = false;
        });
      }
    })
    .catch(() => {
      store.state.isLoading = false;
    });
}
