<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card @click="newProject">
          <v-card-title class="justify-center">
            <v-icon x-large> mdi-account-plus</v-icon>
            {{ $t("project.newProject") }}
          </v-card-title>
        </v-card>
      </v-col>

      <v-col>
        <v-card @click="projectList">
          <v-card-title class="justify-center">
            <v-icon x-large> mdi-format-list-bulleted-square</v-icon>
            {{ $t("project.projectList") }}
          </v-card-title>
        </v-card>
      </v-col>

      <v-col>
        <v-card @click="sentProjectList">
          <v-card-title class="justify-center">
            <v-icon x-large> mdi-format-list-bulleted-square</v-icon>
            {{ $t("project.sentProjectList") }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ProjectButtons",
  methods: {
    newProject() {
      this.$emit("showNewProjectEvent", true);
    },
    projectList() {
      this.$emit("showProjectListEvent", true);
    },
    sentProjectList() {
      this.$emit("showSentProjectListEvent", true);
    },
  },
};
</script>

<style scoped></style>
