<template>
  <v-form ref="formProduct" v-model="valid" @submit.prevent="onSubmit">
    <v-container fluid>
      <v-row>
        <v-col>
          <h1>{{ $t("product.title") }}</h1>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-combobox
            v-model="form.first"
            :items="firstCategory"
            :label="$t('product.firstCategory')"
            @change="changeFirst()"
            small-chips
            outlined
            dense
          >
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip
                v-if="item === Object(item)"
                v-bind="attrs"
                :input-value="selected"
                label
                small
                class="mt-1 mb-1"
              >
                <span class="pr-2">
                  {{ item.value }}
                </span>
              </v-chip>
              <v-chip
                v-else
                v-bind="attrs"
                :input-value="selected"
                label
                small
                class="mt-1 mb-1"
              >
                <span class="pr-2">
                  {{ item }}
                </span>
              </v-chip>
            </template>

            <template v-slot:item="{ index, item }">
              <v-chip dark label small>
                {{ item.value }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-combobox
            v-model="form.second"
            :items="secondCategory"
            :label="$t('product.secondCategory')"
            @change="changeSecond()"
            small-chips
            outlined
            dense
          >
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip
                v-if="item === Object(item)"
                v-bind="attrs"
                :input-value="selected"
                label
                small
                class="mt-1 mb-1"
              >
                <span class="pr-2">
                  {{ item.value }}
                </span>
              </v-chip>
              <v-chip
                v-else
                v-bind="attrs"
                :input-value="selected"
                label
                small
                class="mt-1 mb-1"
              >
                <span class="pr-2">
                  {{ item }}
                </span>
              </v-chip>
            </template>

            <template v-slot:item="{ index, item }">
              <v-chip dark label small>
                {{ item.value }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-combobox
            v-model="form.third"
            :items="thirdCategory"
            :label="$t('product.thirdCategory')"
            small-chips
            outlined
            dense
          >
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip
                v-if="item === Object(item)"
                v-bind="attrs"
                :input-value="selected"
                label
                small
                class="mt-1 mb-1"
              >
                <span class="pr-2">
                  {{ item.value }}
                </span>
              </v-chip>
              <v-chip
                v-else
                v-bind="attrs"
                :input-value="selected"
                label
                small
                class="mt-1 mb-1"
              >
                <span class="pr-2">
                  {{ item }}
                </span>
              </v-chip>
            </template>

            <template v-slot:item="{ index, item }">
              <v-chip dark label small>
                {{ item.value }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            v-model="form.title"
            :label="$t('product.title')"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            v-model="form.description"
            :label="$t('product.description')"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            v-model="form.price"
            :label="$t('product.price')"
            required
            type="number"
            step="0.01"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-file-input
            v-model="images"
            :label="$t('product.images')"
            :clearable="false"
            :disabled="images.length > 0"
            @change="getBase64($event)"
            multiple
            prepend-icon="mdi-paperclip"
            accept="image/png, image/jpeg"
          >
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-col>
      </v-row>

      <v-row v-if="editImages.length > 0">
        <v-col class="text-right">
          <v-carousel :height="calculateHeight()">
            <v-carousel-item
              v-for="img in editImages"
              :key="img.id"
              :src="img.content"
            >
              <v-btn
                class="mr-2 mt-2 elevation-15"
                fab
                dark
                small
                color="delete"
                @click="remove(img)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-center">
          <v-btn color="success" type="submit">
            {{ $t("product.save") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="product">
        <v-col class="text-center">
          <v-btn color="error" @click="removeProduct()">
            {{ $t("product.delete") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import axios from "axios";
import store from "../store";
import { getAllRequestData } from "../cache";

export default {
  name: "NewProductPage",
  props: {
    editMode: Boolean,
    product: Object,
    first: Object,
    second: Object,
    third: Object,
  },
  data: () => ({
    valid: false,
    images: [],
    editImages: [],
    form: {
      first: "",
      second: "",
      third: "",
      title: "",
      description: "",
      price: 0.0,
    },
  }),
  created() {
    this.firstCategory = store.state.firstCategory;
    this.secondCategory = store.state.secondCategory;
    this.thirdCategory = store.state.thirdCategory;

    this.product?.images.forEach((img) => {
      this.editImages.push({
        id: img.id,
        content: store.state.images.get(img.id),
        fileName: null,
      });
    });

    if (this.product) {
      this.form.title = this.product.title;
      this.form.description = this.product.description;
      this.form.price = this.product.price;
    }
    if (this.first) {
      this.form.first = {
        id: this.first.id,
        value: this.first.value,
      };
    }
    if (this.second) {
      this.form.second = {
        id: this.second.id,
        value: this.second.value,
      };
    }
    if (this.third) {
      this.form.third = {
        id: this.third.id,
        value: this.third.value,
      };
    }
  },
  computed: {
    firstCategory: {
      get() {
        return store.state.categories;
      },
      set(value) {
        return value;
      },
    },
    secondCategory: {
      get() {
        let second = this.firstCategory?.filter((category) => {
          return category.id === this.form.first.id;
        });
        return second.length > 0 ? second[0].subCategories : [];
      },
      set(value) {
        return value;
      },
    },
    thirdCategory: {
      get() {
        let third = this.secondCategory?.filter((category) => {
          return category.id === this.form.second.id;
        });
        return third.length > 0 ? third[0].subCategories : [];
      },
      set(value) {
        return value;
      },
    },
  },
  methods: {
    remove(image) {
      if (image.id) {
        axios
          .delete("image/" + image.id)
          .then(() => {
            this.editImages = this.editImages.filter((img) => {
              return img.id !== image.id;
            });
          })
          .catch(() => {
            store.commit("updateAlert", {
              status: "error",
              message: "offer.error",
            });
          });
      } else {
        this.editImages = this.editImages.filter((img) => {
          return img.content !== image.content;
        });
        this.images = this.images.filter((img) => {
          return img.name !== image.fileName;
        });
      }
    },
    getBase64(files) {
      files.forEach((file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.editImages.push({
            id: null,
            content: reader.result,
            fileName: file.name,
          });
        };
        reader.onerror = (error) => {
          console.log("Error: ", error);
        };
      });
    },
    changeFirst() {
      this.form.second = "";
      this.form.third = "";
    },
    changeSecond() {
      this.form.third = "";
    },
    createNewProduct() {
      let formData = new FormData();
      formData.set("form", JSON.stringify(this.form));
      this.images.forEach((img, index) => {
        formData.set("images_" + index, img);
      });
      axios
        .post("/new-product", formData)
        .then(() => {
          getAllRequestData();
          this.form.first = "";
          this.form.second = "";
          this.form.third = "";
          this.form.title = "";
          this.form.description = "";
          this.form.price = 0.0;
          this.images = [];
          this.editImages = [];
          this.$emit("back-to-list");
        })
        .catch(() => {
          store.commit("updateAlert", {
            status: "error",
            message: "product.error",
          });
        });
    },
    updateProduct() {
      this.form.id = this.product.id;
      let formData = new FormData();
      formData.set("form", JSON.stringify(this.form));
      this.images.forEach((img, index) => {
        formData.set("images_" + index, img);
      });
      axios
        .post("/update-product", formData)
        .then(() => {
          getAllRequestData();
          this.form.first = "";
          this.form.second = "";
          this.form.third = "";
          this.form.title = "";
          this.form.description = "";
          this.form.price = 0.0;
          this.form.images = [];
          this.$emit("back-to-list");
        })
        .catch(() => {
          store.commit("updateAlert", {
            status: "error",
            message: "offer.error",
          });
        });
    },
    parseCategories: function () {
      this.form.first =
        typeof this.form.first === "string"
          ? { value: this.form.first }
          : this.form.first;
      this.form.second =
        typeof this.form.second === "string"
          ? { value: this.form.second }
          : this.form.second;
      this.form.third =
        typeof this.form.third === "string"
          ? { value: this.form.third }
          : this.form.third;
      return this.form;
    },
    onSubmit() {
      this.parseCategories();

      let checkValidity =
        this.$refs.formProduct.$el.checkValidity() &&
        this.form.first &&
        this.form.second &&
        this.form.third;
      if (checkValidity) {
        if (this.editMode) {
          this.updateProduct();
        } else {
          this.createNewProduct();
        }
      } else {
        store.commit("updateAlert", {
          status: "error",
          message: "product.valid",
        });
      }
    },
    removeProduct() {
      axios
        .delete("/product/" + this.product.id)
        .then(() => {
          getAllRequestData();
          this.$emit("back-to-list");
        })
        .catch(() => {
          store.commit("updateAlert", {
            status: "error",
            message: "product.error",
          });
        });
    },
    calculateHeight() {
      return window.innerWidth - window.innerWidth * 0.3;
    },
  },
};
</script>

<style scoped>
button {
  width: 100%;
}
</style>
