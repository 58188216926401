<template>
  <v-container fluid>
    <v-row v-for="project in projectList" :key="project.id">
      <v-col>
        <v-btn class="text-break" @click="navigateToProducts(project)">
          {{ `${project.lastName} ${project.firstName}` }}
          <br />
          {{ `${project.email}` }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "../store";
import router from "../router";

export default {
  name: "ProjectListPage",
  created() {
    store.state.selectedProject = null;
    store.state.basket = [];
    store.state.showSentProjectOffer = false;
  },
  computed: {
    projectList: function () {
      return store.state.projectList;
    },
  },
  methods: {
    navigateToProducts(project) {
      store.state.selectedProject = project;
      store.state.basket = project.offerProducts;
      router.push("/offer").catch(() => {});
    },
  },
};
</script>

<style scoped>
button {
  width: 100%;
}
</style>
