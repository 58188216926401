<template>
  <div>
    <v-container fluid>
      <v-form @submit.prevent="onSubmit">
        <v-row class="align-center justify-center">
          <v-col class="text-center">
            <h3>{{ $t("loginPage.title") }}</h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              prepend-icon="mdi-account"
              v-model="email"
              :label="$t('loginPage.email')"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              prepend-icon="mdi-lock"
              v-model="password"
              :label="$t('loginPage.password')"
              required
              type="password"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-center">
            <v-btn type="submit">
              {{ $t("common.loginButton") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import router from "../router";
import { getAllRequestData } from "../cache";
import store from "../store";

export default {
  name: "LoginPage",
  data: () => ({
    email: "",
    password: "",
  }),
  methods: {
    onSubmit() {
      let auth = {
        email: this.email,
        password: this.password,
      };
      axios
        .post(`/login`, auth)
        .then((response) => {
          localStorage.setItem("X-AUTH-USER", response.data?.email || "");
          localStorage.setItem("X-AUTH-TOKEN", response.data?.token || "");
          router.push("/home").catch(() => {});
          getAllRequestData();
        })
        .catch(() => {
          store.commit("updateAlert", {
            status: "error",
            message: "loginPage.error",
          });
        });
    },
  },
};
</script>

<style scoped></style>
