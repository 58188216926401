import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import de from "vuetify/lib/locale/de";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#000000",
        secondary: "#424242",
        accent: "#FFC107",
        error: "#FF5252",
        delete: "#ff0000",
        info: "#2196F3",
        success: "#4CAF50",
        enable: "#4CAF50",
        disable: "#d9d9d9",
        warning: "#FFC107",
        background: "#EFE8E6",
      },
    },
  },
  lang: {
    locales: { de },
    current: "de",
  },
});
