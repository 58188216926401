import i18n from "../i18n";

let mutations = {
  updateAlert: (state, alert) => {
    state.alertStatus = alert?.status || "";
    state.alertMessage = i18n.t(alert?.message || "");

    if (state.alertTimer) clearTimeout(state.alertTimer);
    state.alertTimer = setInterval(() => {
      state.alertStatus = "";
      state.alertMessage = "";
    }, 10000);
    return alert;
  },
};

export default mutations;
