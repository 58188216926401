var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"formProduct",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('h1',[_vm._v(_vm._s(_vm.$t("product.title")))])])],1),_c('v-row',[_c('v-col',[_c('v-combobox',{attrs:{"items":_vm.firstCategory,"label":_vm.$t('product.firstCategory'),"small-chips":"","outlined":"","dense":""},on:{"change":function($event){return _vm.changeFirst()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({staticClass:"mt-1 mb-1",attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.value)+" ")])]):_c('v-chip',_vm._b({staticClass:"mt-1 mb-1",attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item)+" ")])])]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.value)+" ")])]}}]),model:{value:(_vm.form.first),callback:function ($$v) {_vm.$set(_vm.form, "first", $$v)},expression:"form.first"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-combobox',{attrs:{"items":_vm.secondCategory,"label":_vm.$t('product.secondCategory'),"small-chips":"","outlined":"","dense":""},on:{"change":function($event){return _vm.changeSecond()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({staticClass:"mt-1 mb-1",attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.value)+" ")])]):_c('v-chip',_vm._b({staticClass:"mt-1 mb-1",attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item)+" ")])])]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.value)+" ")])]}}]),model:{value:(_vm.form.second),callback:function ($$v) {_vm.$set(_vm.form, "second", $$v)},expression:"form.second"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-combobox',{attrs:{"items":_vm.thirdCategory,"label":_vm.$t('product.thirdCategory'),"small-chips":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({staticClass:"mt-1 mb-1",attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.value)+" ")])]):_c('v-chip',_vm._b({staticClass:"mt-1 mb-1",attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item)+" ")])])]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.value)+" ")])]}}]),model:{value:(_vm.form.third),callback:function ($$v) {_vm.$set(_vm.form, "third", $$v)},expression:"form.third"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('product.title'),"required":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('product.description'),"required":""},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('product.price'),"required":"","type":"number","step":"0.01"},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-file-input',{attrs:{"label":_vm.$t('product.images'),"clearable":false,"disabled":_vm.images.length > 0,"multiple":"","prepend-icon":"mdi-paperclip","accept":"image/png, image/jpeg"},on:{"change":function($event){return _vm.getBase64($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.images),callback:function ($$v) {_vm.images=$$v},expression:"images"}})],1)],1),(_vm.editImages.length > 0)?_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('v-carousel',{attrs:{"height":_vm.calculateHeight()}},_vm._l((_vm.editImages),function(img){return _c('v-carousel-item',{key:img.id,attrs:{"src":img.content}},[_c('v-btn',{staticClass:"mr-2 mt-2 elevation-15",attrs:{"fab":"","dark":"","small":"","color":"delete"},on:{"click":function($event){return _vm.remove(img)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)}),1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"success","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("product.save"))+" ")])],1)],1),(_vm.product)?_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.removeProduct()}}},[_vm._v(" "+_vm._s(_vm.$t("product.delete"))+" ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }