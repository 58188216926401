var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('Navbar'),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[(!_vm.createNewProductPage && !_vm.selectedFirstCategory)?_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){_vm.createNewProductPage = true}}},[_vm._v(" "+_vm._s(_vm.$t("product.createNewProject"))+" ")]):_vm._e(),(_vm.createNewProductPage)?_c('v-btn',{attrs:{"color":"accent"},on:{"click":function($event){_vm.createNewProductPage = false}}},[_vm._v(" "+_vm._s(_vm.$t("common.back"))+" ")]):_vm._e(),(_vm.selectedFirstCategory)?_c('v-btn',{attrs:{"color":"accent"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v(" "+_vm._s(_vm.$t("common.back"))+" ")]):_vm._e()],1)],1),(_vm.createNewProductPage)?_c('NewProductPage',{attrs:{"edit-mode":false,"product":null,"first":null,"second":null,"third":null}}):_vm._e(),(_vm.selectProduct)?_c('NewProductPage',{attrs:{"edit-mode":true,"product":_vm.selectProduct,"first":_vm.selectedFirstCategory,"second":_vm.selectedSecondCategory,"third":_vm.selectedThirdCategory},on:{"back-to-list":function($event){return _vm.backToList()}}}):_vm._e(),(
        !_vm.createNewProductPage &&
        !_vm.selectedFirstCategory &&
        !_vm.selectedSecondCategory &&
        !_vm.selectedThirdCategory &&
        !_vm.selectProduct
      )?_c('v-row',_vm._l((_vm.categories),function(category){return _c('v-col',{key:category.id,staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{on:{"click":function($event){_vm.selectedFirstCategory = category}}},[_vm._v(" "+_vm._s(category.value)+" ")])],1)}),1):_vm._e(),(
        !_vm.createNewProductPage &&
        _vm.selectedFirstCategory &&
        !_vm.selectedSecondCategory &&
        !_vm.selectedThirdCategory &&
        !_vm.selectProduct
      )?_c('v-row',_vm._l((_vm.secondCategories),function(category){return _c('v-col',{key:category.id,staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{on:{"click":function($event){_vm.selectedSecondCategory = category}}},[_vm._v(" "+_vm._s(category.value)+" ")])],1)}),1):_vm._e(),(
        !_vm.createNewProductPage &&
        _vm.selectedFirstCategory &&
        _vm.selectedSecondCategory &&
        !_vm.selectedThirdCategory &&
        !_vm.selectProduct
      )?_c('v-row',_vm._l((_vm.thirdCategories),function(category){return _c('v-col',{key:category.id,staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{on:{"click":function($event){_vm.selectedThirdCategory = category}}},[_vm._v(" "+_vm._s(category.value)+" ")])],1)}),1):_vm._e(),_c('v-spacer'),(!_vm.selectProduct)?_c('v-row',_vm._l((_vm.productList),function(product){return _c('v-col',{key:product.id,attrs:{"cols":"6"}},[_c('v-card',{on:{"click":function($event){_vm.selectProduct = product}}},[_c('v-card-title',[_vm._v(_vm._s(product.title))]),_c('v-card-text',[_vm._v(_vm._s(product.description))])],1)],1)}),1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }