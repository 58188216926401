import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import vuetify from "./vuetify";
import i18n from "./i18n";
import router from "./router";
import "vuetify/dist/vuetify.min.css";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import store from "./store";

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;
axios.interceptors.request.use(
  function (config) {
    store.state.alertMessage = "";
    store.state.alertStatus = "";
    store.state.alertTimer = null;
    if (
      localStorage.getItem("X-AUTH-USER") &&
      localStorage.getItem("X-AUTH-TOKEN")
    ) {
      config.headers.common["X-AUTH-USER"] =
        localStorage.getItem("X-AUTH-USER");
      config.headers.common["X-AUTH-TOKEN"] =
        localStorage.getItem("X-AUTH-TOKEN");
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Vue.filter("price", function (value) {
  if (!value || !store.state.showPrice) return "";
  return value + "€";
});

Vue.filter("totalPrice", function (value) {
  if (!value) return "";
  return value + "€";
});

Vue.filter("tax", function (value) {
  return value.replace("%tax%", store.state.tax * 100 + "%");
});

new Vue({
  i18n,
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
