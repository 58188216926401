<template>
  <section>
    <Navbar />
    <ProjectButtons
      @showNewProjectEvent="
        showNewProject = true;
        showProjectList = false;
        showSentProjectList = false;
      "
      @showProjectListEvent="
        showProjectList = true;
        showNewProject = false;
        showSentProjectList = false;
      "
      @showSentProjectListEvent="
        showSentProjectList = true;
        showProjectList = false;
        showNewProject = false;
      "
    />
    <NewProjectPage
      v-if="showNewProject"
      @showProjectListEvent="
        showProjectList = true;
        showNewProject = false;
      "
    ></NewProjectPage>
    <ProjectListPage v-if="showProjectList"></ProjectListPage>
    <SentProjectListPage v-if="showSentProjectList"></SentProjectListPage>
  </section>
</template>

<script>
import Navbar from "./Navbar";
import ProjectButtons from "./ProjectButtons";
import NewProjectPage from "./NewProjectPage";
import ProjectListPage from "./ProjectListPage";
import SentProjectListPage from "./SentProjectListPage";

export default {
  name: "HomePage",
  data: () => ({
    showSentProjectList: false,
    showProjectList: false,
    showNewProject: false,
  }),
  components: {
    Navbar,
    ProjectButtons,
    NewProjectPage,
    ProjectListPage,
    SentProjectListPage,
  },
};
</script>

<style scoped></style>
